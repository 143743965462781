
import { Component, Vue, Provide, Emit, Prop, Watch } from 'vue-property-decorator'
import { mobileRegExp } from '@/utils/reg'
import httpHelper from '@/utils/request'
import GlobalMixin from '@/mixins/global'
import PactDialog from '@/components/PactDialog.vue'
import { parseSearch } from '@/utils/utils'
import getCurrentQuery from '@/utils/querystrings'
import { isMiniProgram, isWechat } from '@/utils/env'
@Component({
  name: 'authorization',
  components: {
    'pact-dialog': PactDialog
  },
  mixins: [ GlobalMixin ]
})

export default class Authorization extends Vue {
  public name = 'authorization'
  pactDialog: boolean = false
  url: string = ''
  @Prop({
    type: Boolean,
    default: false
  }) public policySelect!: boolean
  @Provide() public list: any = []

  @Prop({
    type: Number,
    default: 200
  }) public top!: number
  public created () {
    this.refresh()
  }

  @Emit()
  public async refresh () {
    try {
      const res = await httpHelper.get({
        url: 'ThirdLogin'
      })
      this.list = res.value
      console.log('res.value' + res.value)
    } catch (err) {
    }
  }

  @Emit()
  public to (url: string) {
    this.url = url
    if (!this.policySelect) {
      this.pactDialog = true
      return
    }
    console.log('接口返回的uri', decodeURIComponent(url))
    const source = getCurrentQuery('source') || ''
    const storeCode = getCurrentQuery('storeCode') || ''
    let thirdLoginUrl = decodeURIComponent(url)
    if ((source === '15' || source === '16') && (isMiniProgram || isWechat)) {
      const search = thirdLoginUrl.split('?')[1] || ''
      const query = parseSearch(`?${search}`)
      let redirectUri = query.redirect_uri
      redirectUri = redirectUri.indexOf('?') > -1 ? `${redirectUri}&source=${source}&storeCode=${storeCode}` : `${redirectUri}?source=${source}&storeCode=${storeCode}`
      query.redirect_uri = redirectUri
      const str = Object.keys(query).map((key) => {
        return `${key}=${query[key]}`
      }).join('&')
      thirdLoginUrl = thirdLoginUrl.split('?')[0] + '?' + str
      console.log(thirdLoginUrl)
    }
    location.replace(thirdLoginUrl)
  }

  public async agree () {
    this.pactDialog = false
    await this.$emit('pactShow')
    this.to(this.url)
  }
  public tourist () {
    this.pactDialog = false
  }
}
