
import { Component, Emit, Prop, Vue } from 'vue-property-decorator'

@Component
export default class PactDialog extends Vue {
  @Emit()
  public close () {
    this.$emit('tourist')
  }
  @Emit()
  public confirm () {
    this.$emit('agree')
  }
}
